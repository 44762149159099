<template>
  <v-combobox
    v-model="keyword"
    :search-input.sync="search"
    placeholder="ชื่อสะพาน หรือเลข กม."
    solo
    prepend-inner-icon="mdi-magnify"
    dense
    hide-details
    append-icon=""
    :items="bridgeSuggest"
    item-text="name"
    item-value="name"
    clearable
    :loading="isSuggestLoading"
    :filter="filterBridge"
    :menu-props="menuprops"
    :no-data-text="nodata"
    @keydown.enter="keydownSuggest"
    @input="inputSuggest"
  >
    <template v-slot:append>
      <span v-if="loadCord" class="caption locationLoading">
        <span class="d-none d-sm-inline">Waiting device's location</span>
      </span>
      <v-icon v-if="loading">mdi-loading mdi-spin-fast</v-icon>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadCord: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    bridgeSuggest: [],
    keyword: '',
    isSuggestLoading: false,
    searchKeywordTimeout: null,
    menuprops: { closeOnContentClick: true, closeOnClick: true },
    nodata: 'ไม่มีสะพานให้เลือก'
  }),
  watch: {
    search (val) {
      clearTimeout(this.searchKeywordTimeout)
      if (!this.search) {
        this.isSuggestLoading = false
        this.bridgeSuggest = []
        clearTimeout(this.searchKeywordTimeout)
        this.search = null
        this.menuprops = { value: false }
        return
      }
      if (val.length < 2) {
        this.isSuggestLoading = false
        this.bridgeSuggest = []
        this.menuprops = { value: false }
        return
      }
      this.isSuggestLoading = true
      this.searchKeywordTimeout = setTimeout(() => {
        this.isSuggestLoading = false
        this.getBridgeSuggest()
      }, 1000)
    }
  },
  beforeMount () {
    if (this.$route.params.keyword) {
      this.keyword = this.$route.params.keyword
      this.search = this.$route.params.keyword
    }
  },
  methods: {
    filterBridge (item, queryText, itemText) {
      return (
        (itemText.includes(queryText) && queryText !== undefined) ||
        item.header ||
        item.id.toString().includes(queryText)
      )
    },
    inputSuggest () {
      if (typeof this.keyword === 'object' && this.keyword !== null) {
        this.closeSuggestMenu()
        this.$emit('input', this.keyword)
      }
    },
    async getBridgeSuggest () {
      this.menuprops = {
        closeOnContentClick: true,
        closeOnClick: true,
        value: false
      }
      const bridgeSuggest = []
      if (this.search) {
        const bridgeAll = await this.$store.dispatch('getBridgeList', {
          keyword: this.search
        })
        for (const section of bridgeAll) {
          bridgeSuggest.push({ header: section.name })
          for (const bridge of section.items) {
            bridgeSuggest.push(bridge)
          }
        }
      }
      this.bridgeSuggest = bridgeSuggest
      this.menuprops = {
        closeOnContentClick: true,
        closeOnClick: true,
        value: true
      }
    },
    keydownSuggest () {
      clearTimeout(this.searchKeywordTimeout)
      this.isSuggestLoading = false
      this.menuprops = {
        closeOnContentClick: true,
        closeOnClick: true,
        value: false
      }

      this.keyword = this.search
      this.$emit('input', this.keyword)
    },
    closeSuggestMenu () {
      this.menuprops = { value: false }
    }
  }
}
</script>

<style lang="scss" scoped>
.locationLoading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  content: '\2026';
  animation: ellipsis steps(4, end) 4s infinite;
  width: 0;
}
</style>
