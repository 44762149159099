<template>
  <v-card>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text">
          สัญลักษณ์
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list dense>
            <v-list-item
              class="pa-0"
              v-for="(legend, indexLegend) in legendItems"
              :key="indexLegend"
            >
              <v-list-item-icon>
                <v-badge
                  v-if="legend.inspection"
                  :color="legend.color"
                  inline
                  dot
                ></v-badge>
                <v-icon v-else>{{ legend.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-if="legend.inspection">
                  {{ srList[indexLegend] ? srList[indexLegend].name : '' }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  {{ legend.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  props: {
    legendItems: Array,
    srList: Array
  }
}
</script>

<style></style>
